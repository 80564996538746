import React from "react";
import * as styles from "./styles";
import { Warning } from "./styles";

import { getTicketLoadingData } from "./getTicketLoadingData";
import getRemainingTickets from "../../../fetch/getRemainingTickets";
import dateFormat from "dateformat";
import "bootstrap/dist/css/bootstrap.min.css";

import PickupLocationsDropdown from './PickupLocationsDropdown'

// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TicketTypeSelects from "./TicketTypeSelects"
import { getTicketTypeSelectedQuantity } from './funcs'

import addOtaProductToCart from './addOtaProductToCart'
import { navigate } from "@gatsbyjs/reach-router"

// const OptionsArray = ({ options }) => {
//   return (
//     <>
//       {options}
//     </>
//   )
// }

// Todo:
// - Remove Testing initialization
// - Disable ticket <select> when no tickets remaining
// - add env vars for use in testing (move dates to the past)
// - create real endpoint for remaining tickets
// - update axios URL
// - handled a non existent sessionId in setSelectedSession(). (as it may have been deleted from rezdy () and not propagated through to netlify)

// renders a form of selects (for each ticket type) based on an array of ticketTypes provided.
// note: the tour/activity date - when this is changed by the user, it will re-request (and update)
// the ticketTypes <select>s and <option>s
export class TicketSelectionForm extends React.Component {
  render() {
    // console.log("ticketTypes (#sdf23wcd): ", this.state.ticketTypes);
    console.log("this.state (#zxa8): ", this.state);

    console.log(
      "this.state.dateTransformWarning (#Rwec23): ",
      this.state.dateTransformWarning
    );

    // return whether submit button is disabled.
    var submitDisabled = () => {
      const { totalQuantity } = this.calculateTotals();
      console.log('totalQuantity (#adsf3fwe) :', totalQuantity)
      if (totalQuantity > 0) {
        return false
      } else {
        return true
      }
      // return this.state.errorMsg ? true : false
    }

    if (this.state["allTicketTypesDisabled"] === true) {
      submitDisabled = () => true;
    }

    console.log('pickup locations #df9g: ', this.pickupLocations)

    return (
      <div class="TicketSelectionForm">
        <form onSubmit={this.handleSubmit}>
          {this.state.noticeMsg && <>{this.state.noticeMsg}</>}
          {this.state.errorMsg && (
            <Warning>
              <b>Error:</b>
              <br />
              {this.state.errorMsg}
            </Warning>
          )}
          <styles.DatePicker>
            <styles.SectionTitle>Choose a date:</styles.SectionTitle>
            <this.OutputDateSelector />
          </styles.DatePicker>
            <styles.SectionTitle>Pickup location:</styles.SectionTitle>
            <PickupLocationsDropdown
              locations={this.pickupLocations}
              selectedPickupLocation={this.state.selectedPickupLocation}
              setSelectedPickupLocation={this.setSelectedPickupLocation}
            />
          {this.state.dateTransformWarning()}
          <styles.SectionTitle>
            Enter number of participants:
          </styles.SectionTitle>
          <b>note:</b> All "releases" are for the same tour bus (for the day
          selected). If you want the cheaper releases (i.e. "first" and
          "second"), please select a later date.
          <br />
          <this.RenderSelectContainer />
          <div style={{'text-align': 'right'}}>
          <input
            type="submit"
            // onClick={() => {onFormSubmit()}}   // this is handled by the form submit
            disabled={submitDisabled()}
            class="btn btn-primary"
            value="Book now"
          />
          </div>
        </form>
      </div>
    );
  }

  setTicketTypeQuantity = (ticketTypeId, newValue) => {
    // const selectName = getTicketTypeSelectName(ticketTypeId);
    console.log('new select value (#sfsdf): ' + newValue + ", for select named: " + ticketTypeId);
    console.log('ticketTypeQuantity (#sfsdf): ', this.state.ticketTypeQuantity)
    // this.state.ticketTypeQuantity[ticketTypeId] = newValue
    // this.state.ticketTypeQuantity[ticketTypeId] = newValue
    const ticketTypeQuantity = this.state.ticketTypeQuantity
    
    this.setState({ ticketTypeQuantity: {...ticketTypeQuantity, [ticketTypeId]: newValue} });
  }

  getTicketTypeQuantity = (ticketTypeId) => {
    // const selectName = getTicketTypeSelectName(ticketTypeId);

    // console.log('new select value (#sfsdf): ' + quantity + ", for select named: " + selectName);
    return this.state.ticketTypeQuantity[ticketTypeId]
  }

  // sets the value to the index on the "pickupLocations" array (*not* the pickupLocationId)
  setSelectedPickupLocation = (arrIndex) => {
    console.log('in setSelectedPickupLocation() (#234wrp): ', arrIndex)
    
    this.setState({
      selectedPickupLocation: arrIndex
    });
  }

  // returns the PickupLocation object
  getSelectedPickupLocationObject = () => {
    const index = this.state.selectedPickupLocation
    
    if (!this.pickupLocations[index]) {
      // alert('adf')
      return null
    }

    return this.pickupLocations[index]
  }

  constructor(props) {
    super(props);
    // console.log('===== zzz122 ---asdas')
    // console.log('===== zzz122', props)
    const { product, addCartItem } = props;
    
    const availableDates = product.fields.availableDates
    const pickupLocations = product.fields.pickupLocations

    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.OutputDateSelector = this.OutputDateSelector.bind(this)
    this.RenderSelectContainer = this.RenderSelectContainer.bind(this)
    // this.outputTicketSelects = this.outputTicketSelects.bind(this);
    // this.setTicketSelectionValue = this.setTicketSelectionValue.bind(this);
    // this.getTicketAvailability = this.getTicketAvailability.bind(this);
    this.disabledAllTicketTypeSelects = this.disabledAllTicketTypeSelects.bind(this)
    this.checkQuantitySelectionIsValid = this.checkQuantitySelectionIsValid.bind(this)
    this.clearWarningMsg = this.clearWarningMsg.bind(this)
    // this.initializeSelects = this.initializeSelects.bind(this)
    this.setSelectedSession = this.setSelectedSession.bind(this)
    this.setSelectedSessionBySessionId = this.setSelectedSessionBySessionId.bind(this)
    this.updateTicketAvailabilitySelects = this.updateTicketAvailabilitySelects.bind(this)
    this.populateCartItems = this.populateCartItems.bind(this)
    this.getNextSessionSessionId = this.getNextSessionSessionId.bind(this)
    this.centsToDollars = this.centsToDollars.bind(this)
    
    this.setTicketTypeQuantity = this.setTicketTypeQuantity.bind(this)

    this.getSessionById = this.getSessionById.bind(this)

    console.log("availableDates (#32ewx23): ", availableDates)
    this.availableDates = availableDates;
    this.pickupLocations = pickupLocations;
    
    if (!this.availableDates) {
      throw Error('Error: there was no availableDates provided to TicketSelectionForm. To fix this, you can use the sample data from the testing folder (within the component).')
    }

    const currentSessionId = this.getNextSessionSessionId()
    const closestUpcomingSession = this.setSelectedSessionBySessionId(currentSessionId)
    console.log('closestUpcomingSession (#zxa5): ', closestUpcomingSession)

    // initialize this.state
    this.state = {
      'product': product,
      'selectedPickupLocation': 'none-selected',
      'productId': product.id,
      'ticketTypeQuantity': {},
      'ticketTypes': getTicketLoadingData(),
      'selectedSession': closestUpcomingSession,
      'addCartItem': addCartItem
    }

    this.setSelectedPickupLocation = this.setSelectedPickupLocation.bind(this)
    // this.clearAllTicketSelections()

    const dateTransformWarning = () => {}
    this.state.dateTransformWarning = dateTransformWarning

    this.state.noticeMsg = ""
    this.state.errorMsg = ""

    // this.initializeSelects();

    console.log("inital state values (#f24f34): ", this.state)
  }

  // initialize the state for each of the ticketType <selects> (and alo the "selectedSessionId")
  // it will load a "loading data..." dataset first while Axios fetchis in the background via setSelectedSession()
  // // it will also default find the next/upcoming tour sessionId (not a "past" activity)
  // initializeSelects() {
  //   // get ticketTypes (from test) and intitialize
  //   const currentSessionId = this.getNextSessionSessionId()
  //   console.log('currentSessionId (#4sdf): ', currentSessionId)
  //   this.setSelectedSessionBySessionId(currentSessionId)
  //   // const selectName = "selectedSession";
  //   // const selectedSession = this.getSessionById(currentSessionId)
  //   // this.state[selectName] = selectedSession;
  //   this.state.ticketTypes = getTicketLoadingData();
    
  //   // console.log("ticketTypes (#fwc23c): ", this.ticketTypes);
  // }

  // Return the sessionId of the next (upcoming) tour. Activity date (from an array of possible dates)
  // todo: check if there's acctually ticket available for this date, if not continue to the next available date.
  getNextSessionSessionId() {
    var nextSesion = {
      sessionId: 0,
      startTimeLocal: 0,
    };

    // console.log("this.availableDates #4fwc23: ", this.availableDates);

    if (this.availableDates) {
      Object.entries(this.availableDates).map((t) => {
        // const index = t[0];
        const curSession = t[1];
        // console.log("bookingAvailability #4fwc23: ", curSession);
        const startTimeLocal = curSession.startTimeLocal;
        // console.log(`startTimeLocal: ${startTimeLocal} < nextSesion.startTimeLocal ${nextSesion.startTimeLocal} ?? (#c2c32)`);

        if (this.isDateInThePast(curSession.startTimeLocal)) {
          return;
        }

        // is event date the earliest in the array?
        if (
          startTimeLocal < nextSesion.startTimeLocal ||
          nextSesion.startTimeLocal === 0
        ) {
          // console.log("(#c2c32): Reassigning");
          nextSesion.startTimeLocal = startTimeLocal;
          nextSesion.sessionId = curSession.sessionId;
          // todo: only allow future tours (not past ones)
        }
      });
    } else {
      // alert ('emptya 34 ')
    }

    // console.log('nextSesion (#c2c32): ', nextSesion)

    return nextSesion.sessionId;
  }

  isDateInThePast(parsableDateTime) {
    const now = new Date();
    const curDate = new Date(Date.parse(parsableDateTime));
    // is event date in the past?
    console.log(
      "now: " +
        now.toLocaleDateString() +
        " <? " +
        "current event date: " +
        curDate.toLocaleDateString() +
        " (#c23d4sc2)"
    );
    const isInPast = now.getTime() > curDate.getTime();
    if (isInPast) {
      console.log("Yes, date is in the past. (#c23d4sc2):");
    }

    return isInPast;
  }

  // setTicketSelectionValue(event) {
  //   // const selectName = event.target.name;
  //   alert("in set value: ");

  //   return 2;
  //   // this.state.TicketSelection
  // }

  // remove any previous warning - will also set the "submit" disabled: false
  clearWarningMsg() {
    this.setState({
      noticeMsg: null,
    });
    this.setState({
      errorMsg: null,
    });
  }

  // triggered when the user changes the activity/tour date (via the drop down field)
  handleDateChange(event) {
    // console.log("#sdfw3wc: ", event.target);
    console.log("Change to date <select> (#sxz2wc):", event.target.value);
    // console.log("(#213fcwc32): ", event.target.value);

    this.setState({
      noticeMsg:
        "Please wait... Ticket data (remaining tickets for each ticket type) is being loaded.",
    });

    const sessionId = event.target.value;
    this.setSelectedSessionBySessionId(sessionId)

    this.clearWarningMsg();
    // this.checkQuantitySelectionIsValid();
  }

  setSelectedSessionBySessionId (sessionId) {
    const selectedSession = this.getSessionById(sessionId)
    // console.log('selectedSession (#zxa8): ', selectedSession)
    
    // const selectedSession = {
    //   sessionId: sessionId,
    //   startTimeLocal: session.startTimeLocal
    // }

    this.setSelectedSession(selectedSession);

    return selectedSession
  }

  getSessionById (sessionId) {
    var session = null
    Object.entries(this.availableDates).map((t) => {
      const curSession = t[1];

      // console.log('curSession.sessionId (#4edfvf34): ' + curSession.sessionId + ' == sessionId: ' + sessionId)
      if (curSession.sessionId == sessionId) {
        // console.log('id MATCHED with a a session (#4edfvf34)')
        session = curSession
      }
    })

    if (!session) {
      // console.log('session (#4edfvf34): ', session)
      throw new Error ('unable to find session with id: ', session)
    }
    
    return session
  }

  // set all ticket selects to 0
  clearAllTicketSelections = () => {
    const ticketTypes = this.state.ticketTypes
    if (!ticketTypes) {
      return true
    }

    Object.entries(ticketTypes).map((t) => {
      const ticketType = t[1]
      this.setTicketTypeQuantity(ticketType.id, 0)
    })

  }

  // update the dateSelect and update the TicketType selects (after an Axios call to get remaining availability)
  async setSelectedSession(session) {
    this.setState(() => {
      return { selectedSession: session }
    }) 

    console.log('this.state (zxa2): ', this.state)
    console.log('set selectedSession (#zxa2): ', session)

    await this.updateTicketAvailabilitySelects(session.sessionId);

    return true
  }

  // check if current quantity (of ticket per ticket type) is available for purchase.
  // (note: this is ussually done when a new date is selected with different quantities available (that may be 'sold out'))
  checkQuantitySelectionIsValid() {
    if (this.getSelectedPickupLocationObject() === null) {
      this.setState({
        errorMsg: `Please select a pickup location.`,
      });

      return false
    }

    this.clearWarningMsg()

    var returnValue = true
    // check selected ticket quantities are acceptable (i.e. tickets are available)
    Object.entries(this.state.ticketTypes).map((t) => {
      const curTicketType = t[1];
      // console.log("in checkQuantitySelectionIsValid(). curTicketType (#323d): ", curTicketType);
      
      // const selectName = getTicketTypeSelectName(curTicketType.id);
      const selectedQuantity = getTicketTypeSelectedQuantity(this.state, curTicketType.id);
      if (selectedQuantity == false) {
        return false
      }

      console.log('remaining spots: ' + curTicketType.seatsRemaining + ', quantity selected (#4drf):' + selectedQuantity)
      if (curTicketType.seatsRemaining < selectedQuantity) {
        this.setState({
          errorMsg: `the ticket type: "${curTicketType.label}" doesn't have ${selectedQuantity} tickets available. Please select your quantity again.`,
        })
        console.log('error (#5345): too many tickets selected - curTicketType: "' + curTicketType.label + '"')

        returnValue = false;
      }
    });

    console.log ('Passed form checks (#5345): ' + returnValue)

    return returnValue
  }

  // sets all quantity selections to 0. It's useful, as setting just one to zero (the "offending" input) may
  // not be detected by the user visually and (potentially) may lead to continuation of the order but now with an erroneous (total) quantity.
  setAllTicketTypeQuantitiesToZero() {
    alert('needs work - not used')
    // Object.entries(this.state.ticketTypes).map((t, k) => {
    //   const ticketType = t[1];
    //   // const selectName = getTicketTypeSelectName(ticketType.id);

    //   this.setState({ ticketTypes[ticketType.id]: 0 });
    // });
  }

  // fetch the TicketTypes (including remaining tickets) for the sessionId provided 9from the backend server).
  // and update/replace the ticketType <select>s
  //
  // todo:
  // - add sessionId parameter (for Axios)
  // error handling
  async updateTicketAvailabilitySelects(sessionId) {
    this.setState({ allTicketTypesDisabled: true });

    // todo: "Loading remaining seats for this activity. Please wait" text
    const newTicketTypes = await getRemainingTickets(sessionId);
    console.log("new ticketTypes: #asfc34wec): ", newTicketTypes);

    console.log("old ticketTypes (#sdf23wcd): ", this.state.ticketTypes);

    if (!newTicketTypes) {
      this.setState({
        errorMsg: `Could not load ticket information from the server. This may be due to internet connection problems. Maybe try a different internet connection?`,
      });
      this.setState({
        noticeMsg:
          null
      });
      return null
      // throw new Error (`the ticket types returned null. Maybe your internet isn\'t connected?`)
    }

    // Initialize state for (each of) the TicketType <select>s form inputs
    // (only create it, if it doesn't yet exist)
    Object.entries(newTicketTypes).map((t) => {
      // console.log("#2rfwc23: ", t);
      // const index = t[0];
      const ticketType = t[1];
      this.initializeSelect(ticketType.id)
    });
    // console.log("state (#2wef23f23): ", this.state);

    this.setState({ allTicketTypesDisabled: false });
    this.setState({ ticketTypes: newTicketTypes });
    this.state.ticketTypes = newTicketTypes;
  }

  // sets select value to 0 if it was previously null
  initializeSelect = (ticketTypeId) => {
    // const selectName = getTicketTypeSelectName(ticketTypeId);

    if (!this.state.ticketTypeQuantity[ticketTypeId]) {
      this.state.ticketTypeQuantity[ticketTypeId] = 0;
      console.log(`Initializing state for ticket type <select>: ${ticketTypeId} (#34rt324rf)`);
    }

    // console.log("state (#2wef23f23): ", this.state);
  }

  // disabled all ticketType <select>s
  disabledAllTicketTypeSelects() {
    // Object.entries(this.ticketTypes).map((t, k) => {
    //   const element = t[1];
    //   console.log("current ticketType: (#E2323d): ", element);
    //   getTicketTypeSelectName(element.id);
    //   // get select by name
    // });
  }

  // checks ticket selection is valid, then adds items to cartItems (that is used by the payment component set) 
  handleSubmit(event) {
    event.preventDefault();
    // todo: get
    // - product id, ticket type id, quantity, pickup location, 
    // - update cart Items
    // - add payload ability (via postman)
    // - implement payload

    console.log("[handleSubmit] state: (#zeciu2): ", this.state);
    console.log("ticket selection [handleSubmit] state: (#zeciu2): " + this.state.TicketSelection)

    const ticketSelectionIsValid = this.checkQuantitySelectionIsValid();
    if (!ticketSelectionIsValid) {
      // do not continue if there are errors in the form.
      console.log('ticket selection not valid. Not continuing.')
      return
    }

    const selectedPickupLocation = this.getSelectedPickupLocationObject()
    console.log('selectedPickupLocation (#zeciu2): ', selectedPickupLocation)

    // work from here: get dateTime id next
    //   - add id to pickup time. 

    console.log('this.state.selectedSession (#4534): ', this.state.selectedSession)

    // const sessionId = this.state.selectedSession.sessionId
    // const session = this.getSessionById(sessionId)
    const selectedSession = this.state.selectedSession
    console.log('selectedSession (#zxa9): ', selectedSession)
    
    // const productId = this.state.productId
    this.populateCartItems(this.state.product, this.state.ticketTypes, selectedSession, selectedPickupLocation)
  }

  // populate the cartItems context with the context of the order.
  populateCartItems = (product, ticketTypes, selectedSession, selectedPickupLocation ) => {
    // console.log('this.state (#zxa9): ', this.state)
    // ??? get this method working.
    // console.log ('in populateCartItems(): ticketTypeQuantity (#32wu2): ', ticketTypeQuantity)

    // create cartItems (for the checkout)
    Object.entries(ticketTypes).map((t) => {
      const ticketType = t[1]
      const curTicketTypeLabel = ticketType.label
      const curTicketTypeId = ticketType.id
      const curTicketTypePrice = ticketType.price
      console.log ('curTicketType (#32wu2): ', ticketType)
      console.log ('selectedPickupLocation (#xxs1): ', selectedPickupLocation)
      // console.log('addCartItem (#32wu2): ', this.state.addCartItem)

      addOtaProductToCart(
        product,
        curTicketTypeId,
        curTicketTypeLabel,
        curTicketTypePrice,
        this.getTicketTypeQuantity(ticketType.id),
        selectedSession.sessionId,
        selectedSession.startTimeLocal,
        selectedPickupLocation,
        this.state.addCartItem
      )
      
      // this.setTicketTypeQuantity(ticketType.id, 0)
      
    })

    navigate('/checkout/');

    // alert("todo: add items to cart process. See console.log() for ticket selection.");
  }

  centsToDollars (cents) {
    // return (cents/100).toLocaleString("en-AU", {currencyDisplay: 'code', style:"currency", currency:"AUD"})
    return 'AUD $' + (cents/100).toFixed(2)
    
    // todo: apply above in all other places
  }

  // Calculate totalPrice (of all tickets selected) and total quantity (in cents)
  calculateTotals() {
    const ticketTypes = this.state.ticketTypes

    var totalPrice = 0
    var totalQuantity = 0
    let totalPriceAsString = 0
    console.log("ticketTypes (#234rf24ew): ", ticketTypes);
    Object.entries(ticketTypes).map((t) => {
      const curTicketType = t[1]
      // console.log("#E2323d: ", element);

      // const selectName = getTicketTypeSelectName(curTicketType.id);
      const curQuantity = getTicketTypeSelectedQuantity(this.state, curTicketType.id)
      console.log('curQuantity (#sdfsdzz): ' + curQuantity)

      // const curQuantity = this.state[selectName];
      totalQuantity = totalQuantity + parseInt(curQuantity);
      const curPrice = curTicketType.price;
      // console.log("curQuantity (#4wecw:): " + curQuantity + " curPrice: " + curPrice);

      totalPrice = totalPrice + curQuantity * curPrice;
      totalPriceAsString = this.centsToDollars(totalPrice)
    });
    // totalPrice = (Math.round(totalPrice * 100) / 100).toFixed(2);

    console.log(`final: totalPrice: ${totalPrice} , totalQuantity: ${totalQuantity} (#sf324wcwe)`);

    return { totalPrice, totalPriceAsString, totalQuantity };
  }

  // Renders a "container" that holds the <select>s and <option>s (for the ticket types)
  // and calculates useful things like totals (of price and seat quantity selected)
  RenderSelectContainer() {
    const { totalPriceAsString, totalQuantity } = this.calculateTotals();

    const leftSizeSml = 6
    const leftSizeLrg = 7
    
    return (
      <>
        {/* {this.outputTicketSelects()} */}
        <TicketTypeSelects
          ticketTypes={this.state.ticketTypes}
          ticketTypeQuantity={this.state.ticketTypeQuantity}
          setTicketTypeQuantity={this.setTicketTypeQuantity}
          disableAll={this.state["allTicketTypesDisabled"]}
        />

        <styles.TicketSelectionContainer>
          <Row>
            <Col xs={leftSizeSml} md={leftSizeSml} lg={leftSizeLrg}>
              Total tickets:
            </Col>
            <Col xs={12-leftSizeSml} md={12-leftSizeSml} lg={12-leftSizeLrg}>
              {totalQuantity} tickets
            </Col>
          </Row>
          <Row>
            <Col xs={leftSizeSml} md={leftSizeSml} lg={leftSizeLrg}>
                Total (AUD):
            </Col>
            <Col xs={12-leftSizeSml} md={12-leftSizeSml} lg={12-leftSizeLrg}>
                <span class="total_price">{totalPriceAsString}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={leftSizeSml} md={leftSizeSml} lg={leftSizeLrg}></Col>
            <Col xs={12-leftSizeSml} md={12-leftSizeSml} lg={12-leftSizeLrg} style={{"textAlign": "left", "font-size": '12px'}}>
              (including GST)
            </Col>
          </Row>
        </styles.TicketSelectionContainer>
      </>
    );

    //   <span>hasdasdasdS</span>;
  }

  // show a list of available dates
  OutputDateSelector() {
    const selectName = "selectedSession";
    const curValue = (this.state[selectName]) ? this.state[selectName].sessionId : null;
    // console.log('selectedSessionId (#45sd4r): ', curValue)

    // Create an array of date options
    const optionsArr = Object.entries(this.availableDates).map(
      (t, k) => {
        const index = t[0];
        const element = t[1];

        const curDate = new Date(element.startTimeLocal);

        console.log("current bookingAvailability: (#2rfw323c23): ", element);
        console.log("curDate (#ECWfc23we): ", curDate);

        // const caption = curDate.toDateString() + " ";
        // for more on dateFormat: https://stackoverflow.com/questions/3552461/how-to-format-a-javascript-date
        // note: h:MMTT removed, as there may be multiple pickup locations (that each have their own pickup times).
        const caption = dateFormat(curDate, "d/mmmm/yyyy - dddd");

        const sessionId = element.sessionId;
        const disabled = this.isDateInThePast(element.startTimeLocal);

        return (
          <option disabled={disabled} value={sessionId}>
            {caption}
          </option>
        );
      }
    );

    return (
      <select
        value={curValue}
        onChange={this.handleDateChange}
        name={selectName}
      >
        {optionsArr}
      </select>
    );
  }
}
